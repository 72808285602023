/* Slick-carousel */

.slick-slider {
  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      z-index: 999;
      width: 30px;
      height: 30px;
      &::before {
        font-size: 30px;
      }
    }
    &.slick-prev {
      left: 0.5rem;
    }
    &.slick-next {
      right: 0.5rem;
    }
  }
  .slick-list {
    .slick-track {
      .slick-slide {
        position: relative;
        color: $white;
      }
    }
  }
}