.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(252, 146, 0, 0.3);
    border-top-color: #fc9200;
    animation: 1.5s spin infinite linear;
  }
}

:host-context(.sidebar-icon-only) {
  .spinner-wrapper {
    width: calc(100% - #{$sidebar-width-icon} - 4rem);
  }
}

:host-context(.sidebar-mini) {
  .spinner-wrapper {
    width: calc(100% - #{$sidebar-width-mini} - 4rem);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
