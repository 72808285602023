/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~nouislider/distribute/nouislider.min.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-bootstrap-typeahead/css/Typeahead-bs4.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/widgets";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/accordions";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/tooltips";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/popovers";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/bootstrap-alerts";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/portfolio";
@import "../assets/styles/components/pricing-table";
@import "../assets/styles/components/email/mail-list-container";
@import "../assets/styles/components/email/mail-sidebar";
@import "../assets/styles/components/email/message-content";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/tickets";
@import "../assets/styles/components/project-list";
@import "../assets/styles/components/product-tile";
@import "../assets/styles/components/user-listing";
@import "../assets/styles/components/landing";
@import "../assets/styles/components/chats";
@import "../assets/styles/components/kanban";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/contex-menu";
@import "../assets/styles/components/plugin-overrides/no-ui-slider";
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/rating";
@import "../assets/styles/components/plugin-overrides/date-picker";
@import "../assets/styles/components/plugin-overrides/wizard";
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/sweet-alert";
@import "../assets/styles/components/plugin-overrides/full-calendar";
@import "../assets/styles/components/plugin-overrides/react-photo-gallery";
@import "../assets/styles/components/plugin-overrides/react-tag-autocomplete";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

$theme-colors-primary: #fc9200;

.issEmp {
  .tab-pane {
    max-height: 500px;
    overflow: hidden !important;
  }

  .tab-content {
    border: 1px solid #ebedf2;
    border-top: 0;
    padding: 1rem 1rem;
    text-align: justify;
  }
}

.datefilter {
  width: 300px;
  max-width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;

  [class*="fa-"] {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0 !important;
    border: 0;
    /* width: 100%; */
  }
}

.date-picker {
  z-index: 2000;
  overflow: hidden;
  width: 200px !important;
}

.para {
  font-size: 14px !important;
  padding-top: 10px !important;
}

img {
  max-width: 100%;
}

.card-img-top {
  max-width: 150px;
  max-height: 150px;
}

.card-top {
  max-width: 150px;
  max-height: 150px;
}

.group-header {
  padding: 1rem;
  margin-bottom: 0;
  color: #fff;
}

.group-card {
  box-shadow: 0 10px 22px -7px #0000002e;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  overflow: hidden;
}

.data-tabs {
  .nav-tabs {
    border: none;

    .nav-link {
      padding: 10px 16px;
      border: none;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      min-width: 100px;
      margin-bottom: 0;
      color: #000;
      text-align: center;
      background: transparent;

      &.active::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 100%;
        height: 10px;
        width: 10px;
        background: inherit;
        clip-path: polygon(
          10% 33%,
          26% 59%,
          44% 76%,
          67% 90%,
          100% 100%,
          0 100%,
          0 0
        );
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      z-index: 1;
      position: relative;
      color: #343a40;
      background-color: #ffffff;
    }
  }
}

.modal-dialog.form-modal {
  @media (min-width: 992px) {
    max-width: 750px;
  }

  .modal-content {
    background-color: #fff;
  }

  .modal-body {
    padding: 1.5rem;
  }
}

.modal-dialog.table-modal {
  @media (min-width: 992px) {
    max-width: 850px;
  }

  .modal-content {
    background-color: #fff;
  }

  .modal-body {
    padding: 1.5rem;
  }
}

.form-control {
  border-color: #d6dae3;
}

select.form-control {
  color: #495057;
  outline-color: #d6dae3;

  &:focus {
    outline-color: #80bdff;
  }

  &.is-invalid {
    outline-color: #dc3545;
  }
}

.search-data {
  max-width: 400px;

  .form-control {
    background: #fff url(../assets/images/search.svg) no-repeat right 10px
      center;
    border-bottom: 1px solid #ebedf2;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.counter {
  position: relative;
  max-width: 260px;
  margin: auto;

  .form-control {
    padding: 14px 46px;
    text-align: center;
  }

  .btn {
    position: absolute;
    top: 0;
    height: 100%;
    margin: 0;
    padding: 14px 18px;

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }
}

.stars {
  background: url(../assets/images/star-nocolor.png) no-repeat;
  width: 112px;
  height: 15px;

  .progress {
    background: url(../assets/images/star-color.png) no-repeat;
    height: 100%;
  }
}

.pointer {
  cursor: pointer;
}

.sec-gap {
  margin-bottom: 5rem !important;
}

.big-tabs.nav-tabs {
  border-bottom: 2px solid theme-color(primary);

  .nav-link {
    font-weight: 700;
    font-size: 0.8rem;
    border: none;
    background: none;
    padding: 0.9rem 1.8rem;

    &.active {
      color: #fff;
      background-color: theme-color(primary);
    }
  }

  + .tab-content {
    border: none;
  }
}

.btn.load-more {
  width: 100%;
  border: 1px solid #e2e2e2;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 20px;
  border-radius: 0;

  .fab,
  .fas {
    font-size: 18px;
    vertical-align: -3px;
    margin-right: 3px;
  }
}

.autocomplete-dropdown-container {
  overflow: hidden;
  background: #f5f4f3;
  color: #363636;

  .suggestion-item {
    padding: 6px 10px;
    cursor: pointer;

    &.active {
      background: $theme-colors-primary;
      color: #fff;
    }

    &:first-child {
      margin-top: 3px;
    }

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

input.location-search-input {
  padding-left: 14px;
  padding-right: 38px;
}

.slick-slider .slick-arrow {
  &.slick-prev,
  &.slick-next {
    z-index: 10;
  }
}

.slick-slider.carousel {
  .slick-list {
    margin: 0 -2vw;
  }

  .slick-slide {
    padding: 0 2vw;
  }
}

.user-view {
  background-color: #fff;

  > .container-fluid.page-body-wrapper:not(.full-page-wrapper) {
    padding: 0;
    display: flex;

    > .main-panel {
      width: calc(100% - 260px);

      > .content-wrapper {
        padding: 1.75rem 0;
        background: none;
      }
    }
  }

  @media (min-width: 1366px) {
    .container {
      max-width: 1320px;
    }
  }

  @media (min-width: 1640px) {
    .container-fluid {
      padding: 0 6%;
    }

    .container {
      max-width: 1480px;
    }
  }

  #header {
    margin-bottom: 48px;

    .header-wrapper {
      position: relative;
      background: #fff;
      padding: 18px 0;
      z-index: 100;
      box-shadow: rgba(100, 100, 111, 0.13) 0px 2px 8px 0px;
    }

    .navbar .navbar-menu-wrapper {
      height: auto;
      width: auto;
      padding: 0;

      .navbar-nav .nav-item .nav-link {
        height: 46px;
        margin-left: 0;
      }
    }

    &.sticky {
      .header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: slideFromTop 0.3s forwards;
      }
    }
  }

  @keyframes slideFromTop {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  .main-menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    margin: 0 -14px;

    > li {
      margin: 0 18px;

      > a {
        position: relative;
        display: block;
        text-decoration: none;
        color: #000;
        padding: 12px 0;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #000;
          transform: scaleX(0);
          transition: 0.3s;
        }
      }

      &:hover > a::after {
        transform: scaleX(1);
      }

      &.dropdown {
        position: relative;

        ul {
          list-style: none;
          display: none;
          overflow: hidden;
          transition: 0.3s ease-in;

          @media (min-width: 1200px) {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 250px;
            background-color: #fff;
            box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
            padding: 10px 0;
            transform: translateY(20px);
            opacity: 0;
            visibility: hidden;
          }

          a {
            color: inherit;
            font-size: 16px;
            text-decoration: none;
            display: block;
            padding: 6px 15px;

            &:hover {
              color: #fff;
              background-color: $theme-colors-primary;
            }
          }
        }

        &:hover ul {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .hamburger-wrapper {
      position: absolute;
      top: 4px;
      right: 4px;
      margin: 0;
    }
  }

  .search-group {
    position: relative;
    margin-right: 16px;

    .search-toggle {
      border: none;
      background: none;
      padding: 0;
      font-size: 28px;
      line-height: 1;
      height: 46px;

      @media (max-width: 479px) {
        font-size: 24px;
      }

      svg {
        height: 1em;
        width: 1em;
      }
    }
  }

  .hamburger-wrapper {
    display: none;
    padding: 10px;
    cursor: pointer;

    @media (min-width: 480px) {
      margin-right: 10px;
    }
  }

  .hamburger {
    width: 20px;
    line-height: 0;
    color: #000000;

    span {
      display: inline-block;
      position: relative;
      width: 20px;
      height: 2px;
      vertical-align: top;
      background: currentColor;

      + span {
        margin-top: 5px;
      }

      &:nth-child(1) {
        animation: ease 0.7s 0.4s burger_top-2-45 backwards;
      }

      &:nth-child(2) {
        animation: ease 0.7s 0.4s burger_scaled-2 backwards;
      }

      &:nth-child(3) {
        animation: ease 0.7s 0.4s burger_bottom-2-135 backwards;
      }
    }
  }

  @media (max-width: 1199px) {
    .hamburger-wrapper {
      display: block;
    }

    .main-menu {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      background: #f1f1f1;
      z-index: 10;
      width: 100%;
      max-width: 400px;
      transform: translateX(-100%);
      transition: transform 0.45s ease-in-out;
      flex-direction: column;
      justify-content: center;
      padding: 10px 16px;
      margin: 0;
      overflow: auto;
    }

    .overlay {
      visibility: hidden;
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 5;
      background: #000000bf;
      width: 100%;
      transition: 0.45s ease-in-out;
    }

    &.open-menu {
      .main-menu {
        transform: translateX(0);
      }

      .overlay {
        opacity: 1;
        visibility: visible;
      }

      .hamburger {
        span:nth-child(1) {
          animation: ease 0.7s 0.4s burger_top-1-45 forwards;
        }

        span:nth-child(2) {
          animation: ease 0.7s 0.4s burger_scaled-1 forwards;
        }

        span:nth-child(3) {
          animation: ease 0.7s 0.4s burger_bottom-1-135 forwards;
        }
      }
    }
  }

  @keyframes burger_top-1-45 {
    0% {
      top: 0;
      transform: rotate(0);
    }

    50% {
      top: 7px;
      transform: rotate(0);
    }

    100% {
      top: 7px;
      transform: rotate(45deg);
    }
  }

  @keyframes burger_top-2-45 {
    0% {
      top: 7px;
      transform: rotate(45deg);
    }

    50% {
      top: 7px;
      transform: rotate(0);
    }

    100% {
      top: 0;
      transform: rotate(0);
    }
  }

  @keyframes burger_bottom-1-135 {
    0% {
      bottom: 0;
      transform: rotate(0);
    }

    50% {
      bottom: 7px;
      transform: rotate(0);
    }

    100% {
      bottom: 7px;
      transform: rotate(135deg);
    }
  }

  @keyframes burger_bottom-2-135 {
    0% {
      bottom: 7px;
      transform: rotate(135deg);
    }

    50% {
      bottom: 7px;
      transform: rotate(0);
    }

    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes burger_scaled-1 {
    50% {
      transform: scale(0);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes burger_scaled-2 {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  .book-section {
    background: #000 url(../assets/images/user/bgc.jpg) no-repeat;
    background-size: cover;

    .content-part {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 20% 15px;

      .text-wrapper {
        margin: auto;
        text-align: center;
        color: rgba(255, 255, 255, 0.9);

        h1 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          margin-bottom: 34px;
        }

        .cst-btn {
          font-size: 14px;
          color: inherit;
          border: 2px solid;
          padding: 10px 20px;
          transition: 0.3s;

          &:hover {
            background: #fff;
            border-color: #fff;
            color: #000;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .book-section {
      height: 100vh;
      display: flex;
      justify-content: flex-end;

      .content-part {
        width: 50%;
        display: flex;
        padding: 0;
      }
    }
  }

  .socials a {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #000 !important;
    text-decoration: none !important;
    background: linear-gradient(90deg, #f1e3cf 50%, transparent 50.01%)
      no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    border-radius: 21px;
    padding-right: 16px;
    transition: 0.3s;

    &:hover {
      background-position: 0 0;
    }

    .fab,
    .fas {
      display: flex;
      color: $theme-colors-primary;
      font-size: 18px;
      width: 34px;
      flex: 0 0 auto;
    }
  }

  #footer {
    margin: 5% 0;
    padding: 30px 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
    font-size: 15px;
    color: #000;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: inherit;
    }

    address {
      font-size: 1rem;
    }

    .title {
      font-size: 18px;
    }

    .nav-wrapper {
      @media (min-width: 1200px) {
        border-left: 1px solid #b5b5b5;
        border-right: 1px solid #b5b5b5;
        // min-width: 230px;
        text-align: center;
      }
    }

    .navs {
      list-style: none;
      padding: 0;
      display: inline-block;
      text-align: left;
      font-size: 15px;
      font-weight: 500;

      li:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .socials a {
      margin-bottom: 36px;
      font-size: 15px;
    }

    .contact {
      img {
        margin-bottom: 1rem;
        max-width: 210px;
      }

      .fab,
      .fas {
        color: $theme-colors-primary;
        font-size: 12px;
        margin-left: 16px;
      }
    }
  }
}

.btn.cst-btn {
  font-size: 16px;
  // min-width: 150px;
  padding: 15px 25px;
  font-weight: 600;
  border-radius: 0;
}

@media (max-width: 480px) {
  #header .logo img {
    width: 150px;
  }

  .btn.cst-btn {
    padding: 12px 16px;
    font-size: 14px;
  }
}

.link {
  color: #000;
}

.link:hover {
  color: $theme-colors-primary;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.booking-view {
  $offset: 260px;

  .sidebar {
    background: #fc9200;
    position: fixed;
    margin-top: 69px;
    top: 0;
    height: calc(100vh - 69px);
    align-self: flex-start;
    min-height: auto;
    overflow: auto;
    z-index: 10;

    .nav .nav-item .nav-link,
    .nav .nav-item .nav-link i.menu-icon {
      color: #fff;
    }

    .nav.sub-menu .nav-item .nav-link:before,
    .nav .nav-item .nav-link i.menu-arrow:before,
    .nav .nav-item .nav-link .menu-title {
      color: #fff;
    }
  }

  .sidebar .nav .nav-item.active .nav-link .menu-title {
    font-weight: bold !important;
  }

  .main-panel {
    margin-left: $offset;
  }

  header {
    margin-bottom: 0 !important;
  }
}

.MTableHeader-header-13,
.MuiTableCell-head {
  background-color: #f9fafb !important;
  font-size: 100%;
}

.tablefixed .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  font-size: 80% !important;
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiToolbar-gutters {
  padding-left: 16px !important;
}

.nav-tabs .nav-link {
  font-size: 0.7em !important;
}

.MuiTypography-h6 {
  font-size: 120% !important;
}

.property-modal-lghh .modal-content {
  height: 600px;
}

.property-modal-lgh .modal-header {
  text-align: center !important;
  display: block;
  color: #fc9200;
  font-weight: bold;
}

.property-modal-lghh .modal-header {
  text-align: center !important;
  display: block;
  color: #fc9200;
  font-weight: bold;
}

.prop-item {
  padding: 22px 25px;
  background: #f5f5f5;
  box-shadow: 4px 3px 6px #0000001f;
}

.link {
  text-align: left;
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #fc9200;
  opacity: 1;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.epcard {
  min-height: 380px !important;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #fff;
  background: transparent;
}

.cursor {
  cursor: pointer;
}

.style_toast-list__378gH {
  z-index: 9999 !important;
}

.confirm-box {
  z-index: 99999 !important;
}

.text-end {
  text-align: end;
}

@media (max-width: 479px) {
  .flexw {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    a {
      padding: 10px 10px;
    }
  }

  .flexwrapjus {
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin-bottom: 20px !important;
    }
  }
}

.react-confirm-alert-overlay {
  z-index: 99999 !important;
  background: transparent;
}

.react-confirm-alert-button-group > button {
  width: 160px !important;
  padding: 10px !important;
  border: 1px solid #fff !important;
  margin: 10px !important;
  cursor: pointer;
  background: none !important;
  color: #fff !important;
  font-size: 14px !important;
}

.react-confirm-alert-body {
  background: #fc9200 !important;
  color: #fff !important;
  z-index: 9999 !important;
}

.set-border {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

// .sidebar .nav .nav-item.active {
//   background: #da8107 !important;
// }

.epcard {
  padding: 15px 15px !important;
}

.epcard .title {
  font-size: 14px !important;
  font-weight: bold !important;
}

.epcard p {
  font-size: 14px !important;
}

.listcard {
  padding: 10px 15px !important;
}

.listcard h4,
.listcard p {
  font-size: 14px !important;
}

.columncard {
  position: relative;
  height: 380px !important;
}

.columncardss {
  position: relative;
  height: 180px !important;
}

.columncards {
  height: 360px !important;
}

.columncardlink a {
  position: absolute;
  bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .columncard h4,
  .columncard p {
    font-size: 13px !important;
  }
}

.desccard {
  // background: #fbfbfb 0% 0% no-repeat padding-box;
  // box-shadow: 4px 3px 6px #0000001f;
  opacity: 1;
}

.desccard .slick-prev:before,
.desccard .slick-next:before {
  color: #fc9200;
}

.banner-slide {
  display: flex !important;
  justify-content: center;
}

.showing .active {
  color: #fc9200;
}

.prop-item h6 {
  font-size: 13px !important;
  color: #6a6a6a;
}

.prop-item .title span {
  font-size: 15px !important;
}

.booking-view .sidebar .nav .nav-item.submenu:hover {
  background: #fc9200 !important;
  color: #ffffff !important;
}

.sidebar .nav .nav-item:hover {
  background: transparent !important;
}

.booking-view .sidebar .nav .nav-item.submenu:hover a,
.booking-view .sidebar .nav .nav-item.submenu:hover i,
.booking-view .sidebar .nav .nav-item.submenu:hover span {
  color: #fff !important;
}

.prop-item {
  padding: 15px 15px !important;
}

.links:hover {
  color: #fc9200 !important;
}

@media (max-width: 991px) {
  .main-panel {
    margin-left: 60px !important;
    width: 100% !important;
  }
}

.sidebarline {
  position: absolute;
  top: 24px;
  left: 210px;
  z-index: 999;
}

.sidebaractive {
  display: none;
}

.mainactive {
  margin-left: 0 !important;
}

.property-head .tab-content {
  background-color: #ffffff;
}

.property-head .nav-tabs .nav-link {
  background-color: inherit;
  border: none;
  font-size: medium;
  font-weight: 600;
}

.property-head .nav-tabs .nav-link.active {
  color: #fc9200;
  background-color: #ffffff;
  border-color: #ebedf2 #ebedf2 #ffffff;
  box-shadow: rgb(0 0 0 / 15%) 2.95px 0 2.6px;
}

.property-head .tab-content {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  border: none;
  box-shadow: rgb(0 0 0 / 15%) 2.95px 0 2.6px;
  margin-bottom: 3rem;
  padding: 2rem 2rem !important;
}

.property-head .plan-name {
  font-weight: 600;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}

.inclusion-table,
.exclusion-table {
  overflow-y: auto;
}

.inclusion-table table,
.exclusion-table table {
  height: 100%;
  width: 100%;
}

.slick-list > div {
  margin-left: 0;
}

.amenity-img {
  width: 35px;
  height: 35px;
}

.lgsize.modal-dialog {
  width: 930px;
  max-width: 930px !important;
}

.MuiTableCell-root {
  padding: 4px 16px;
}

.csvd {
  background: white;
  border: none;
  color: #fc980f;
}

.csvd:hover {
  text-decoration: underline;
}

@media (min-width: 320px) and (max-width: 374px) {
  .lgsize {
    width: 303px !important;
  }

  .booking-view .sidebar {
    margin-top: 60px !important;
    padding-top: 0px !important;
  }

  .banner-slide img {
    width: 100%;
  }

  .tab-content {
    padding: 10% 0% !important;
    margin: 0 !important;
  }

  .sidebar .nav .nav-item .collapse {
    z-index: 999;
    position: fixed;
    left: 70px;
    background: #fc9200;
    margin-top: -45px;
  }

  .Component-horizontalScrollContainer-16 {
    z-index: 0 !important;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .lgsize {
    width: 360px !important;
  }

  .booking-view .sidebar {
    margin-top: 60px !important;
    padding-top: 0px !important;
  }

  .banner-slide img {
    width: 100%;
  }

  .tab-content {
    padding: 10% 0% !important;
    margin: 0 !important;
  }

  .content-wrapper h2,
  .breadcrumb .breadcrumb-item,
  .listcard h4,
  .listcard p {
    font-size: 12px !important;
  }

  .prop-item {
    padding: 5px 5px !important;
  }

  .breadcrumb {
    padding: 0.16rem 0.13rem !important;
  }

  .sidebar .nav .nav-item .collapse {
    z-index: 999;
    position: fixed;
    left: 70px;
    background: #fc9200;
    margin-top: -45px;
  }

  .Component-horizontalScrollContainer-16 {
    z-index: 0 !important;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .lgsize {
    width: 405px !important;
  }

  .booking-view .sidebar {
    margin-top: 60px !important;
    padding-top: 0px !important;
  }

  .tab-content {
    padding: 10% 0% !important;
    margin: 0 !important;
  }

  .content-wrapper h2,
  .breadcrumb .breadcrumb-item,
  .listcard h4,
  .listcard p {
    font-size: 12px !important;
  }

  .prop-item {
    padding: 5px 5px !important;
  }

  .breadcrumb {
    padding: 0.16rem 0.13rem !important;
  }

  .sidebar .nav .nav-item .collapse {
    z-index: 999;
    position: fixed;
    left: 70px;
    background: #fc9200;
    margin-top: -45px;
  }

  .Component-horizontalScrollContainer-16 {
    z-index: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .lgsize {
    width: 768px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .columncard {
    height: 380px !important;
  }

  .columncards {
    height: 360px !important;
  }
}

.input-group .see-password {
  text-decoration: none;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  color: #fc9200;
  padding: 8px 10px;
}

.input-group .see-password {
  bottom: 0px;
  font-size: 90%;
}

.forgot-password {
  bottom: -40px;
  font-size: 16px;
  font-weight: 500;
}

.forgot-password {
  text-decoration: none;
  border: none;
  background: none;
  // position: absolute;
  right: 0;
  color: #fc9200;
  padding: 8px 0;
}

.log-page .form-sec input {
  padding: 8px 15px !important;
  font-size: 13px !important;
}

.user-view .containers {
  max-width: 1074px !important;
}

.links:hover {
  text-decoration: underline;
}

.inclustion:nth-child(odd) {
  background: #fff;
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 10px 15px !important;
}

.inclustion:nth-child(even) {
  background: #f5f5f5;
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 10px 15px !important;
}

.dates .react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px !important;
  left: 0px !important;
  transform: translate3d(20px, 46px, 0px) !important;
}

// .float-left .react-datepicker-popper {
//   top: 0px !important;
//   left: 0px !important;
//   transform: translate3d(20px, -342px, 0px) !important;
// }

.css-yk16xz-control,
.css-1pahdxg-control:hover,
.css-2b097c-container span,
.css-1hwfws3 {
  max-height: 55px !important;
  // overflow-y: scroll;
}

.sidebar .nav .nav-item .nav-link.menu-expanded .menu-arrow {
  transform: rotate(-90deg);
}

.css-1wa3eu0-placeholder {
  top: 37% !important;
}

.css-tlfecz-indicatorContainer {
  margin-top: -13px;
}

.loading-background .loading-bar .loading-circle-1,
.loading-background .loading-bar .loading-circle-2 {
  background-color: #fc9200 !important;
}

.emplist table th:nth-child(1),
.emplist table th:nth-child(2),
.emplist table th:nth-child(3) {
  width: 20% !important;
}

.emplist table th:nth-child(4) {
  width: 15% !important;
}

.emplist table th:nth-child(5) {
  width: 10% !important;
}

.inclusion-table,
.exclusion-table {
  max-height: 300px !important;
  height: auto !important;
}

.forget-wrapper {
  height: 100vh;
  display: flex;
  background: #fff;
}

.forget-form {
  width: 330px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.dcCEdk {
  max-height: 270px !important;
  overflow-y: auto !important;
}

.flexwrapjuss {
  justify-content: unset !important;
}

.kriIxi {
  height: 30px !important;
}

.rdt_TableRow {
  min-height: 38px !important;
}

.availability-statuss.online {
  background: #59cf1f;
}

.availability-statuss {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  bottom: 5px;
  right: -1px;
}

.prop-item p {
  font-size: 0.837rem !important;
  color: #6a6a6a;
}

.columncardlink a {
  font: normal normal 600 14px/19px Montserrat !important;
}

.title {
  font-weight: bold !important;
}

.iVJMFP {
  height: 250px !important;
}

.emplist .Component-horizontalScrollContainer-12 {
  height: 300px;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1100px;
  }
}

.cards {
  // padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  background: white;

  .card_header {
    padding: 18px;
    border-bottom: 1px solid #ddd;
  }
}

.dashboard_card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  background: white;
  cursor: pointer;
  min-height: 196px;

  .heading {
    text-align: center;

    h5 {
      font-weight: 500 !important;
    }
  }

  .subheading {
    background: #fc9200;
    padding: 10px;
    color: white;
    text-align: center;

    h6 {
      font-weight: bold;
    }
  }

  .submodal {
    border: 1px solid #ddd;
  }

  .subbody {
    text-align: center;
    padding: 10px 0;
  }
}

.seats .col-4:nth-child(1) {
  padding-right: 5px;
}

.seats .col-4:nth-child(2) {
  padding: 0 5px;
}

.seats .col-4:nth-child(3) {
  padding-left: 5px;
}

.dashboard_card:hover {
  box-shadow: rgb(0 0 0 / 24%) 0px 36px 83px;
  background: #fc920012;

  .subbody {
    background: #fff;
  }
}

.log-pages {
  background: #fff;
  position: relative;
  display: flex;

  .logo {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 154px;
  }

  label {
    font-size: 14px;
  }

  select.form-control {
    outline: 0 !important;
  }

  .banner-sec {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
  }

  .form-sec {
    width: 50%;
    display: flex;
    color: #1a1a1a;
    font-size: 15px;

    p {
      font-size: 15px;
    }

    .log-form {
      width: 100%;
      max-width: 472px;
      margin: auto;
    }

    .title {
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .sub-head {
      margin-bottom: 44px;
    }

    .inputs-wrapper {
      margin-bottom: 70px;
    }

    .input-group {
      position: relative;
      margin-bottom: 36px;

      label {
        position: absolute;
        bottom: 0;
        margin-bottom: 8px;
        pointer-events: none;
        opacity: 0.5;
        transition: 0.3s;
      }

      &.selectlabel label {
        margin-bottom: 43px;
      }

      &.isfocus label {
        font-size: 14px;
        bottom: 31px;
      }

      &.isfocuss label {
        font-size: 14px;
        bottom: 40px;
      }

      .forgot {
        position: absolute;
        bottom: 0;
        right: 0;
        text-decoration: none;
        color: #fc9200;
        font-size: 18px;
        padding: 8px 0;
      }
    }

    input,
    select {
      width: 100%;
      font-size: 15px;
      color: #1a1a1a;
      border: none;
      padding: 8px 0;
      border-bottom: 1px solid rgba(26, 26, 26, 0.12);
    }

    .sign-with {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 52px;

      a {
        color: #fc9200;
        text-decoration: none;
        font-size: 32px;
        margin-left: 2.2vw;
      }
    }

    .btns {
      display: flex;

      .btn {
        flex: 1;
        margin: 0;
        border-radius: 0;
        font-size: 18px;
      }

      .log-btn {
        font-weight: 400;

        i {
          font-size: 80%;
        }
      }

      .sign-btn {
        border: 1px solid #d4d4db;
        text-align: left;
        font-weight: 600;
        color: inherit;

        small {
          display: block;
          margin-bottom: 8px;
          font-size: 72%;
          opacity: 0.7;
        }
      }
    }
  }
}

.btn-primary {
  background: #fc9200 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 !important;
}

.btn-primary:hover {
  background: #fff !important;
  border: 1px solid #fc9200 !important;
  color: #fc9200 !important;
}

.btn-secondary {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 !important;
  color: #000 !important;
  border: none;
}

.btn-secondary:hover {
  background: #fff !important;
  border: 1px solid #000 !important;
  color: #000 !important;
}

input:focus {
  border-bottom-color: #fc9911 !important;
}

.rdt_TableHeadRow {
  background: #fc9200 !important;
  color: white;
  font-weight: 500;
  font-size: 13px;
}

.sc-ckVGcZ,
.iVJMFP {
  border: 1px solid #ddd;
}

.dashcard .card .card-body {
  padding: 3rem 1.5rem !important;
}

.dashcard .card h5 {
  font-size: 14px !important;
  font-weight: bold;
}

.tab-pane {
  max-height: 400px;
  overflow-y: auto;
}

.employeeonboard {
  .MuiToolbar-root {
    display: none;
  }

  .react-datepicker-popper {
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 46px, 0px) !important;
  }
}

.sidebar-icon-only {
  .user-view .containers {
    max-width: 1270px !important;
  }
}

.bulktable {
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    font-size: 80% !important;
    padding: 0 1px !important;
  }
}

.modal-md {
  height: 500px !important;
  width: 600px !important;
  max-width: 600px !important;
}

.modal-md .modal-content {
  height: 100% !important;
}

.modal-md .slick-slide img {
  height: 500px;
}

.modal-md .slick-prev:before,
.slick-next:before {
  color: #fc9911 !important;
}

.modal-md .slick-slider .slick-arrow.slick-next {
  right: 0.5rem;
}

.modal-md .slick-slider .slick-arrow.slick-prev {
  left: 0.5rem;
}

.tab-content {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;
}

.property-head .nav-tabs .nav-link.active {
  box-shadow: 0 3px 4px -6px #0003, 0px 0px 2px #00000024, 0 2px 5px #0000001f !important;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item .nav-profile-image,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-profile-text {
    display: none !important;
  }
}

.sidebar-icon-only .sidebar .nav .nav-item .mdi-menu {
  color: #ab4c09;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
    display: block !important;
  }
}

.nav .nav-profile {
  padding: 0 0 0 1.25rem !important;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item {
    padding: 0 !important;
  }
}

.sidebar-icon-only .sidebar {
  width: 70px;
}

.hamburger-menu {
  width: 32px;
  cursor: pointer;
  line-height: 8px;
  z-index: 999;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hamburger-menu span {
  display: inline-block;
  width: 32px;
  height: 2px;
  background: #af4901;
  transition: all 0.5s;
}

.hamburger-menu span:nth-child(2) {
  width: 22px;
}

.hamburger-menu span:last-child {
  width: 12px;
}

.hamburger-menu.active span {
  margin-top: 15px;
  position: absolute;
  width: 32px;
}

.hamburger-menu.active span:first-child {
  transform: rotate(-135deg);
}

.hamburger-menu.active span:nth-child(2) {
  transform: rotate(135deg);
}

.hamburger-menu.active span:last-child {
  margin-top: 30px;
  width: 0px;
  display: none;
}

.content-wrapper h2 {
  font-size: 20px;
  color: #fc9200 !important;
  margin-bottom: 0 !important;
}

.switch-off {
  padding-left: 3rem !important;
  margin-top: -6px !important;
}

.switch-on.btn {
  padding-right: 0 !important;
  margin-left: -35px !important;
  margin-top: -5px !important;
}

.switch-on {
  right: 100% !important;
}

.stretch-card > .card {
  min-height: 155px;
}

.bulktable {
  .MuiTableCell-head {
    padding: 10px 5px !important;
  }
}

.bulktable .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
}

.bulktable th:nth-child(10) {
  width: 8% !important;
}

.bulktable th:nth-child(9) {
  width: 9% !important;
}

.bulktable th:nth-child(8) {
  width: 13% !important;
}

.bulktable th:nth-child(7) {
  width: 10% !important;
}

.bulktable th:nth-child(6) {
  width: 10% !important;
}

.bulktable th:nth-child(5) {
  width: 10% !important;
}

.bulktable th:nth-child(4) {
  width: 10% !important;
}

.bulktable th:nth-child(3) {
  width: 8% !important;
}

.bulktable th:nth-child(2) {
  width: 10% !important;
}

.bulktable th:nth-child(1) {
  width: 5% !important;
}

table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

table::-webkit-scrollbar-thumb {
  background-color: gray;
}

table::-webkit-scrollbar-thumb:window-inactive {
  background-color: #ccc;
}

.css-1g48xl4-IndicatorsContainer,
.css-1wy0on6 {
  display: none !important;
}

.css-1hwfws3 {
  overflow-y: scroll !important;
}

.bulktable {
  .MuiTableRow-root {
    td:nth-child(5) {
      padding-left: 6px !important;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #fc9200;
  background-color: #fc9200;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3f51b5 !important;
}

.modal-dialog-centered {
  min-height: calc(100% - 42px) !important;
}

.showicon i {
  display: none;
}

.showicon:hover i {
  display: inline-flex;
}

.employeeimg .modal-md .modal-content {
  height: auto !important;
}

.uploaddoc {
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 15px 15px !important;
  }
}

.allocateseat {
  .react-datepicker {
    display: flex !important;
  }
}

.employeeonboard,
.allocateseat {
  .employeeonboard,
  .allocateseat {
    .react-datepicker__input-container {
      width: 100% !important;
    }
  }
}

.swal2-html-container {
  padding: 10px !important;
}

.exportcsv:hover {
  border: none !important;
  border-bottom: 1px solid #fc9911 !important;
}

.approvecorps .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  padding: 15px 5px !important;
  font-size: 12px !important;
}

.approvecorps .MuiTableCell-head {
  font-size: 12px !important;
  padding: 15px 5px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}

.approvecorps .MuiToolbar-regular {
  padding-left: 5px !important;
}

.approvecorps .MuiTypography-h6 {
  font-weight: 600 !important;
  line-height: 1.6;
  font-size: 16px !important;
}

.notification_danger {
  padding: 2px;
  background-color: red;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 17px;
  position: absolute;
  font-size: 11px;
  right: -18px;
  text-align: center;
  top: -4px;
}

.multiselect .kn-multi_select__wrapper___30BEc {
  height: 200px !important;
}

.kn-column__column___3Iwzx:last-of-type {
  display: none !important;
}

.search-box {
  font-weight: 600;
  padding-left: 1px !important;
}

.tabcolor a {
  box-shadow: none !important;
}

.bulktable .MuiIcon-root {
  font-size: 1rem !important;
}

.bulktable .MuiIconButton-sizeSmall {
  padding: 1px !important;
}

.react-daterange-picker__calendar {
  z-index: 99999 !important;
}

.react-daterange-picker__wrapper {
  padding: 6px !important;
}

.font12 {
  font-size: 11px;
}

.dropdownform {
  position: absolute;
  margin-left: -18px;
  z-index: 9999;
}

.reservtable {
  .MuiToolbar-root {
    display: none;
  }
}

.reservationtab .tab-content {
  padding: 1rem 0.5rem !important;
}

.reservationtab .MuiTableSortLabel-root {
  font-size: 13px;
  white-space: nowrap;
}

.f-13 {
  font-size: 13px;
}

.reservationtab .tabpadd {
  overflow-y: hidden;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  width: 140px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.childTbl table tr {
  vertical-align: top !important;
}
